<template>
  <warehouses-movements-table />
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import WarehousesMovementsTable from '../components/warehouses-movements/MovementsTable.vue'
  export default {
    name: 'WarehousesMovements',
    components: {
      WarehousesMovementsTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse']),
    },

    async created () {
      await this.fetchWarehouseMovements({ id: this.actionWarehouse || this.$route.params.id })
      await this.fetchWarehouseInventory({ id: this.actionWarehouse || this.$route.params.id })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('warehouse')
      // await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_WAREHOUSE',
      }),
      ...mapActions([
        'fetchWarehouseMovements',
        'fetchWarehouseInventory',
        'fetchProducts',
        // 'fetchProductLine',
        // 'fetchProductClassification',
        // 'fetchProductCategory',
        // 'fetchProductType',
        // 'getWarehousesByParentId',
      ]),
    },
  }
</script>
