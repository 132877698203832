<template>
  <div>
    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="light-blue darken-1"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openedEditDialog = true"
        >
          <v-icon size="25">
            mdi-magnify-plus-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para ver el detalle del movimiento</span>
    </v-tooltip>

    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="primary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="downloadPdf"
        >
          <v-icon size="25">
            mdi-download
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para descargar la orden de movimiento</span>
    </v-tooltip>

    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="primary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="printPdf"
        >
          <v-icon size="25">
            mdi-printer
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para imprimir la orden de movimiento</span>
    </v-tooltip>

    <!-- <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn> -->
    <movements-detail-dialog
      :id="item.id"
      v-model="openedEditDialog"
    />
  </div>
</template>

<script>
  import MovementsDetailDialog from './MovementsDetailDialog.vue'
  import { mapState, mapActions } from 'vuex'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'

  export default {
    name: 'MovementsActions',
    components: {
      MovementsDetailDialog,
    },
    props: {
      item: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingProducts',
        'user',
        'actionsParentBusiness',
        'fetchingWarehouseInputsDetail',
        'warhouseInputsDetail',
      ]),
    },
    methods: {
      ...mapActions([
        'fetchProducts',
        'deleteProducts',
        'activeProducts',
        'fetchWarhouseInputsDetail',
      ]),

      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },

      async genPdf (print = false) {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        const img = await this.getImageByUrl('/logo.png')

        doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
        doc.setTextColor(255, 0, 0)
        doc.text(`CÓDIGO: ${this.item.code}`, 130, 20)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18).setTextColor(0, 0, 0).text('Nota de movimiento', 82, 60)

        doc.autoTable({
          columns: [
            { title: 'Codigo del producto', dataKey: 'codigo' },
            { title: 'Nombre del producto', dataKey: 'description' },
            { title: 'Cantidad', dataKey: 'cant' },
            { title: 'Unidad', dataKey: 'salesUnit' },
          ],
          body: this.warhouseInputsDetail.map(item => {
            return {
              ...item,
              cant: Math.abs(item.cant),
            }
          }),
          margin: {
            left: 10,
            top: 65,
          },
        })
        doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 30, doc.internal.pageSize.width, doc.internal.pageSize.height - 30)
        doc.setFontSize(10)
        doc.text('Observaciones:', 5, doc.internal.pageSize.height - 25)
        doc.setFontSize(8)
        doc.text(this.item.description, 10, doc.internal.pageSize.height - 20)
        doc.setFontSize(15)
        doc.text(`Total: ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 10)

        if (print) {
          doc.autoPrint()
          doc.output('dataurlnewwindow')
        } else {
          doc.save('nota-de-movimiento.pdf')
        }

        // doc.output('dataurlnewwindow')
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      async downloadPdf () {
        await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.genPdf()
      },

      async printPdf () {
        await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.genPdf(true)
      },
      // async onDeactiveWarehouse () {
      //   await this.deleteProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
      // async onActiveWarehouse () {
      //   await this.activeProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
