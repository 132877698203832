var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-arrow-left-right-bold","title":"Movimientos entre almacenes","color":"orange"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.warhouseMovements,"loading":_vm.fetchingWarehouseMovements,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('movements-table-top')]},proxy:true},{key:"item.description",fn:function(ref){
          var input = ref.item;
return [(input.description)?_c('span',[_vm._v(" "+_vm._s(input.description)+" ")]):_c('span',[_vm._v(" ... ")])]}},{key:"item.createdAt",fn:function(ref){
          var input = ref.item;
return [_vm._v(" "+_vm._s(new Date(input.createdAt).getDate())+"/"+_vm._s(new Date(input.createdAt).getMonth() + 1)+"/"+_vm._s(new Date(input.createdAt).getFullYear())+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('movements-actions',{attrs:{"item":item}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }