<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="warhouseMovementsDetail"
      :loading="fetchingWarehouseMovementsDetail"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    />
    <!-- {{ id }} -->
    <!-- <pre>{{ (warhouseInputsDetail) }}</pre> -->
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'OutputDetailTable',

    props: {
      id: {
        type: Number,
        default () {
          return -1906
        },
      },
      dialog: {
        type: Boolean,
        default () {
          return false
        },
      },
    },

    data () {
      return {
        headers: [
          {
            text: 'Codigo del producto',
            value: 'codigo',
          },
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Cantidad',
            value: 'cant',
          },
          {
            text: 'Unidad',
            value: 'salesUnit',
          },
          {
            text: 'Empaque',
            value: 'packingCant',
          },
          {
            text: 'Unidad de empaque',
            value: 'packingUnit',
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fetchingWarehouseMovementsDetail',
        'warhouseMovementsDetail',
      ]),
    },

    watch: {
      async dialog (v) {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log(v)
        if (v) {
          await this.fetchWarehouseMovementsDetail({ id: this.id })
        }
      },
    },

    async mounted () {
      // // // // // // // // // // // // // // // // // // // // // // // // console.log('id', this.id)
      await this.fetchWarehouseMovementsDetail({ id: this.id })
    },

    methods: {
      ...mapActions([
        'fetchWarehouseMovementsDetail',
      ]),
    },

  }
</script>
